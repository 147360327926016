import { graphql, useStaticQuery } from 'gatsby';

export const useAISectionData = () => {
    const images = useStaticQuery(graphql`
        {
            ai: file(
                relativePath: { eq: "ai-assistant/ai-assistant-viz.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        width: 674
                        placeholder: NONE
                        layout: CONSTRAINED
                    )
                }
            }
        }
    `);

    return {
        image: images.ai.childImageSharp.gatsbyImageData,
        altKey: 'ai-assistant-section.alts.image',
    };
};
